import React from 'react'
import { Link } from 'gatsby-plugin-intl'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Button from '../Forms/Button/Button'
import './style.css'

export default function ImageBlock({
  type,
  img,
  label,
  title,
  style,
  btnlabel,
  children,
  goto,
  lightBlock,
  isTitileH2,
  isSubtitleH2,
  isTitileH3,
  isTitleH1,
}) {
  const breakpoints = useBreakpoint()
  if (type === '1') {
    return (
      <div className="imageBlock1">
        <div className="description1">
          {isTitileH2 ? (
            <h2 className="text1">{label}</h2>
          ) : (
            <div className="text1">{label}</div>
          )}
          {btnlabel && goto ? (
            <Link to={goto} style={{ textDecoration: 'none' }}>
              <Button
                style={{ background: 'white' }}
                data-action="cta scopri"
                data-name-cta={btnlabel}
                label={btnlabel}
              />
            </Link>
          ) : (
            btnlabel && <Button label={btnlabel} />
          )}
        </div>
        <img alt="imageBlockIMG" src={img} className="img1" />
      </div>
    )
  }
  if (type === '2') {
    return (
      <div className="imageBlock2">
        <img alt="imageBlockIMG" src={img} className="img2" />
        <div className={lightBlock ? 'light_layer2' : 'layer2'}>
          {isTitileH2 ? (
            <h2 className={lightBlock ? 'light_title2' : 'title2'}>{title}</h2>
          ) : isTitileH3 ? (
            <h3 className={lightBlock ? 'light_title2' : 'title2'}>{title}</h3>
          ) : (
            <div className={lightBlock ? 'light_title2' : 'title2'}>
              {title}
            </div>
          )}
          {!isSubtitleH2 ? (
            <div className={lightBlock ? 'light_label2' : 'label2'}>
              {label}
            </div>
          ) : (
            <h2 className={lightBlock ? 'light_label2' : 'label2'}>{label}</h2>
          )}
          {btnlabel && goto ? (
            <Link to={goto} style={{ textDecoration: 'none' }}>
              <Button
                data-action="cta scopri"
                data-name-cta={btnlabel}
                label={btnlabel}
              />
            </Link>
          ) : (
            btnlabel && (
              <Button
                data-action="cta scopri"
                data-name-cta={btnlabel}
                label={btnlabel}
              />
            )
          )}
        </div>
      </div>
    )
  }
  if (type === '3') {
    return (
      <div
        className="imageBlock3"
        style={{ height: !breakpoints.sm ? 560 : '60vh', ...style }}
      >
        <img alt="imageBlockIMG" src={img} className="img3" />
        <div className="layer3">
          {!children && isTitleH1 ? (
            <h1 className="title3">{title}</h1>
          ) : (
            <div className="title3">{title}</div>
          )}
          {children && children}
        </div>
      </div>
    )
  }

  if (type === '4') {
    return (
      <div
        className="imageBlock4"
        style={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: 'no-repeat',

          backgroundPosition: 'center',
          height: !breakpoints.sm ? 560 : '60vh',
          ...style,
        }}
      >
        {!children && <div className="title3">{title}</div>}
        {children && children}
      </div>
    )
  }
}
