import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";



const useStyles = makeStyles(() => ({
    dialogHeader: {
      padding:20,
      borderRadius:20,
      
    },
    dialogTitle: {
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: 15,
        
    },
  }));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function Modal({label, text, styleBtn, handleClick}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    handleClick();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  setTimeout(function(){ handleClose() }, 3000);

  return (
    <>
      <Button style={{...styleBtn}} onClick={handleClickOpen}>
        {label}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            component="h5"
            className={classes.dialogTitle}
          >
            {text}
          </Typography>
        </div>
      </Dialog>
    </>
  );
}