import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { map } from 'lodash';
import './style.css';
import { useIntl } from 'gatsby-plugin-intl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ImageBlock from '../ImageBlock/ImageBlock';
import Modal from './Modal';
import Button from '../Forms/Button/Button';
import arrR from '../../assets/icons/arrR.svg';
import MetaTags from '../Header/MetaTags';

const copyToClipboard = (id) => {
  const copyText = document.getElementById(id);
  const sampleTextarea = document.createElement('textarea');
  document.body.appendChild(sampleTextarea);
  sampleTextarea.value = copyText.getAttribute('value'); // save main text in it
  sampleTextarea.select(); // select textarea contenrs
  document.execCommand('copy');
  document.body.removeChild(sampleTextarea);
};

const renderSection = (data) => {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  return (
    <div
      className="sostienici_bonifico"
    >
      <div
        className="sostienici_bonifico_title"
      >
        {data.fieldLabel}
      </div>
      <input
        disabled
        id="bonifico"
        type="text"
        className="sostienici_bonifico_input"
        value={data.fieldValue}
      />

      <Modal
        label={data.fieldButtonLabel}
        text={intl.formatMessage({ id: 'copyed_to_clipboard' })}
        styleBtn={{
          margin: breakpoints.md && '15px 0',
          backgroundColor: '#003F6E',
          color: 'white',
          padding: '10px 0',
          fontSize: 15,
          width: 140,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        handleClick={() => {
          copyToClipboard('bonifico');
          // alert(intl.formatMessage({ id: 'copyed_to_clipboard' }))
        }}
      />
    </div>
  );
};

const renderBollettino = (data) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: !breakpoints.md ? 'center' : 'flex-start',
        flexDirection: !breakpoints.md ? 'row' : 'column',
        margin: !breakpoints.md ? 0 : '0 auto',
        width: breakpoints.md && 280,
      }}
    >
      <div
        style={{
          margin: !breakpoints.md ? '0 40px' : '10px 0',
          fontWeight: 'bold',
        }}
      >
        {data.paymentMethod}
      </div>
      <input
        id="bollettino"
        type="text"
        style={{
          border: 'none',
          fontWeight: 300,
          fontStyle: 'normal',
          backgroundColor: 'white',
          padding: 10,
          fontSize: !breakpoints.md ? 15 : 13,
          width: !breakpoints.md ? 340 : 250,
          marginRight: !breakpoints.md ? 20 : 0,
        }}
        value={data.iban ? data.iban : '100000000'}
      />
      <div
        style={{
          margin: breakpoints.md && '10px 0',
          backgroundColor: '#003F6E',
          color: 'white',
          padding: '10px 0',
          fontSize: 15,
          width: 140,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          copyToClipboard('bollettino');
          alert(intl.formatMessage({ id: 'copyed_to_clipboard' }));
        }}
      >
        {data.buttonLabel}
      </div>
    </div>
  );
};

const renderSportelli = (data) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: !breakpoints.md ? 'center' : 'flex-start',
        flexDirection: !breakpoints.md ? 'row' : 'column',
        margin: !breakpoints.md ? 0 : '0 auto',
        width: '100%',
      }}
    >
      <div
        style={{
          width: !breakpoints.md ? 350 : 200,
          marginBottom: breakpoints.md && 10,
        }}
      >
        {data.paymentMethod}
      </div>
      <input id="sportelli" value={data.paymentMethod} style={{ zIndex: -2 }} />
      <div
        style={{
          backgroundColor: '#003F6E',
          color: 'white',
          padding: '10px 0',
          fontSize: 15,
          width: 180,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        onClick={() => {
          copyToClipboard('sportelli');
          alert(intl.formatMessage({ id: 'copyed_to_clipboard' }));
        }}
      >
        {data.buttonLabel}
      </div>
    </div>
  );
};

const renderNumeroVerde = (data) => {
  const breakpoints = useBreakpoint();
  return (
    <div
      style={{
        display: 'flex',
        alignItems: !breakpoints.md ? 'center' : 'flex-start',
        flexDirection: !breakpoints.md ? 'row' : 'column',
        margin: !breakpoints.md ? 0 : '0 auto',
        width: breakpoints.md && 280,
      }}
    >
      <div
        style={{
          margin: !breakpoints.md ? '0 40px' : '10px 0',
          fontWeight: 'bold',
        }}
      >
        {data.name[0]
          .toUpperCase()
          .concat(data.name.substring(1).toLowerCase())}
      </div>
      <div
        style={{
          fontWeight: 300,
          fontStyle: 'normal',
          backgroundColor: 'white',
          padding: 10,
          fontSize: 15,
          width: !breakpoints.md ? 340 : 280,
          marginRight: !breakpoints.md ? 20 : 0,
        }}
      >
        {data.tel ? data.tel : '+39 0000000'}
      </div>
      <a
        href={`tel:${data.tel}`}
        style={{
          margin: breakpoints.md && '10px 0',
          backgroundColor: '#003F6E',
          color: 'white',
          padding: '10px 0',
          fontSize: 15,
          width: 140,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          textDecoration: 'none',
        }}
      >
        {data.buttonLabel}
      </a>
    </div>
  );
};

const renderTestamento = (data) => <div>{data.name}</div>;

function Sostienici({ data }) {
  const intl = useIntl();
  const breakpoints = useBreakpoint();
  const { yoast_head_json } = data[intl.locale];

  const [collapse, setCollapse] = useState(true);
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' });

  const {
    foundationHero,
    descriptionParagraph,
    companyBlock,
    findFiscalBenefits,
  } = data[intl.locale].acf;
  const { donations } = data[intl.locale].acf.howDonate;
  const [selectedDonationType, setSelectedDonationType] = useState(
    donations[0].donationName.toUpperCase(),
  );

  const selectDonationType = useCallback((don) => {
    setSelectedDonationType(don);
  }, []);

  const donationTypes = useMemo(
    () => map(donations, (don) => (
      <div
        className={
            don.donationName.toUpperCase()
            === selectedDonationType.toUpperCase()
              ? 'sostienici_ricercaSingleCategorySelected'
              : 'sostienici_ricercaSingleCategory'
          }
        data-selected-id={don.donationName}
        selected={selectedDonationType === don.donationName}
          // data-donation-name={don.donationName}
        onClick={() => selectDonationType(don.donationName)}
      >
        {don.donationName}
      </div>
    )),
    [donations, selectedDonationType],
  );

  const donationTypesBody = useMemo(
    () => map(donations, (don) => (
      <div
        className="sostienici_ricercaSingleCategory_Body"
        style={{
          display:
              don.donationName.toUpperCase()
              === selectedDonationType.toUpperCase()
                ? 'block'
                : 'none',
          maxWidth: breakpoints.md && 330,
          margin: breakpoints.md && '50px auto',
        }}
      >
        <h2 className="sostienici_ricercaSingleCategory_Body__title">
          {don.donationName[0].concat(
            don.donationName.substring(1).toLowerCase(),
          )}
        </h2>
        <div
          dangerouslySetInnerHTML={{ __html: don.donationDescription }}
          className="sostienici_ricercaSingleCategory_Body__desc"
        />

        {don.donationInfo.map(((donInfo) => (
          <div className="sostienici_ricercaSingleCategory_Body__data">
            <div className="sostienici_ricercaSingleCategory_Body__data_title">
              {donInfo.title}
            </div>
            <div className="sostienici_ricercaSingleCategory_Body__data_desc">
              {renderSection(donInfo)}
            </div>
          </div>
        )))}

      </div>
    )),
    [donations, selectedDonationType],
  );

  useEffect(() => {
    const mylist = document.getElementById('sostienici');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="sostienici" className="sostienici_main_container">
        {(foundationHero.title && foundationHero.imageBackgroundFoundationHero) && (
        <div style={{ width: '100%' }} id={foundationHero.positionFoundationHero}>
          <ImageBlock
            type="3"
            title={foundationHero.title}
            img={foundationHero.imageBackgroundFoundationHero}
          />
        </div>
        )}

        {(descriptionParagraph.paragraphTitle && descriptionParagraph.contentVisibile) && (
        <div
          id={descriptionParagraph.positionParagraph}
          className="sostienici_body"
        >
          <div
            className="sostienici_desc"
            style={{
              maxWidth: breakpoints.md && 330,
              margin: breakpoints.md && '0 auto',
            }}
          >
            <div id={descriptionParagraph.positionParagraph}>
              <div className="collapsableTextBox">
                <h1 className="titleBox">
                  {descriptionParagraph.paragraphTitle}
                </h1>
                {descriptionParagraph.contentVisibile && (
                <div className="collapsable">
                  <div className="collapsableText">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: collapse
                          ? descriptionParagraph.contentVisibile
                          : descriptionParagraph.contentVisibile.concat(
                            descriptionParagraph.contentHidden,
                          ),
                      }}
                    />
                  </div>

                  {descriptionParagraph.contentHidden && (
                  <span style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                  >
                    <Button
                      handleClick={() => {
                        setCollapse(!collapse);
                      }}
                      id="collapseBtn"
                      label={btnLabel}
                      style={{
                        borderRadius: 0,
                        textTransform: 'uppercase',
                        color: 'black',
                        fontStyle: 'normal',
                        fontWeight: 300,
                        fontSize: '14px',
                        lineHeight: '21px',
                      }}
                    >
                      {collapse ? (
                        <img
                          alt="accordionIMG"
                          src={arrR}
                          style={{
                            marginLeft: 6,
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      ) : (
                        <img
                          alt="accordionIMG"
                          src={arrR}
                          style={{
                            marginLeft: 6,
                            width: '16px',
                            height: '16px',
                            transform: 'rotate(-180deg)',
                          }}
                        />
                      )}
                    </Button>
                  </span>
                  )}
                </div>
                )}
              </div>
            </div>
          </div>
          <div className="sostienici_donate">{donationTypesBody}</div>
        </div>
        )}

        {(findFiscalBenefits.titleFiscalBenefits && findFiscalBenefits.backgroundImageFiscalBenefits) && (
        <div id={findFiscalBenefits.positionFiscalBenefits} style={{ width: '100%' }}>
          <ImageBlock
            isTitileH2
            isSubtitleH2
            type="2"
            img={findFiscalBenefits.backgroundImageFiscalBenefits}
            label={findFiscalBenefits.descriptionFiscalBenefits ? findFiscalBenefits.descriptionFiscalBenefits : ''}
            title={findFiscalBenefits.titleFiscalBenefits}
            btnlabel={findFiscalBenefits.buttonLabelFiscalBenefits ? findFiscalBenefits.buttonLabelFiscalBenefits : 'NaN'}
            goto="/benefici-fiscali"
            lightBlock={!findFiscalBenefits.blockLight}
          />
        </div>
        )}

        {(companyBlock.companyBackgroundImage && companyBlock.companyTitle) && (
        <div id={companyBlock.positionCompany} style={{ width: '100%' }}>
          <ImageBlock
            type="2"
            img={companyBlock.companyBackgroundImage}
            label={companyBlock.companyDescription}
            title={companyBlock.companyTitle}
            btnlabel={companyBlock.companyButtonLabel}
            lightBlock={!!companyBlock.blockLight}
            goto={companyBlock.companyButtonLink}
          />
        </div>
        )}
      </div>
    </>
  );
}

export default Sostienici;
