import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Sostienici from '../components/Sostienici/Sostienici';

import MainLayout from '../layaouts/MainLayout/MainLayout';

function SostieniciPage({ pageContext }) {
  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <Sostienici data={data} />
    </MainLayout>
  );
}

export default injectIntl(SostieniciPage);
