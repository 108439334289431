import React from 'react';
import './style.css';

function Button({
  label, handleClick, children, style = {}, id, reference = null, ...rest
}) {
  return (
    <button
      {...rest}
      type="button"
      id={id || null}
      ref={reference}
      style={{
        ...style,

        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 3,
        cursor: 'pointer',
        
      }}
      className="btn"
      onClick={handleClick}
    >
      <span>{label}</span>
      {children}
    </button>
  );
}

export default Button;
